// This file is automatically generated by process_images.sh
const images = [
  {filename: "d327d559882c10bce4d1b2ae299ab5c8.jpg", title: ""},
  {filename: "ca3eefa00d8caa76749e490f44eeafe2.jpg", title: ""},
  {filename: "3fec0491fda0b68218f410b6b6cfdc21.jpg", title: ""},
  {filename: "74f9b0dcc0793f1e323e8c37ddc3587f.jpg", title: ""},
  {filename: "91cbbc3a03cd96cdbcf354fdaf926b2f.jpg", title: "Hadseløya"},
  {filename: "dc0994ec9ff590e06ca5c52c65a51542.jpg", title: "Andenes fyr"},
  {filename: "955eb73e1e4cc8b0c755c38bbe5a9504.jpg", title: ""},
  {filename: "9939a477559791d8f7790cb3b74f1eb4.jpg", title: "Henningsvær"},
  {filename: "2466f26e98b6874ce46b873d14b5fe46.jpg", title: ""},
  {filename: "9986dd345b81b63c6bc6e09d17297351.jpg", title: ""},
  {filename: "a3ee1f46c647d41540d07a377af84098.jpg", title: ""},
  {filename: "d335a39b1d955f0126cf49f19c10b63d.jpg", title: "Oslo Opera House"},
  {filename: "bbc52d150f706b6c2af93eb588dd274e.jpg", title: ""},
  {filename: "0c443a02e65f7618e9f2baedcb624528.jpg", title: ""},
  {filename: "5449e2e031a544cb572474e6aa0fd438.jpg", title: ""},
  {filename: "df54ed82ed4388c9af2d3de76a6d1d56.jpg", title: "Tokyo"},
  {filename: "87393a124bfb255cc1071bea903a829b.jpg", title: "Tokyo"},
  {filename: "b1eb2aaf0a481877e15a0dd66abe3b41.jpg", title: "Venezia"},
];
export default images;
